<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <!--<BaseCard heading="CALENDARIO DE COMITÉS PERSONALES">-->
        <BaseCard>
          <EventoCalendario></EventoCalendario>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CalendarioYComites',
  components: {
    EventoCalendario: () => import('../components/EventoCalendario')
  },
  data: () => ({
    page: {
      title: 'Comités Personales'
    },
    breadcrumbs: [
      {
        text: 'Calendario Y Comités',
        disabled: true
      },
      {
        text: 'Comités Personales',
        disabled: false
      }
    ]
  }),
  methods: {}
};
</script>
