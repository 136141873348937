<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs" height="50px">
      <template v-slot:item-right>
        <div class="d-flex" style="height: 40px; font-weight: 500;">
          <v-select v-model="anio" :items="listAnios" @change="initialize"></v-select>
        </div>
      </template>
    </BaseBreadcrumb>
    <v-row v-auth-acl="'gh-act-dash-read'">
      <v-col cols="12" sm="12">
        <v-card>
          <v-col cols="12" sm="12" lg="12" class="py-1">
            <h2>1. COMPROMISOS POR MES/ÁREAS/ESTATUS/%</h2>
          </v-col>
          <v-divider></v-divider>
          <v-card color="pa-0 mt-3 mb-3" elevation="0">
            <v-card-text class="py-0 px-3">
              <v-row class="pa-0 d-flex justify-end pt-4 pb-0">
                <v-col cols="12" sm="12" lg="4" class="py-0">
                  <CompromisosXMes :datos="datos"></CompromisosXMes>
                </v-col>
                <v-col cols="12" sm="12" lg="4" class="py-0">
                  <CompromisosXEstatus :datos="datos"></CompromisosXEstatus>
                </v-col>
                <v-col cols="12" sm="12" lg="4" class="py-0">
                  <CumplimientoXCompromiso :datos="datos"></CumplimientoXCompromiso>
                </v-col>
              </v-row>
              <v-row class="pa-0 d-flex justify-end py-0">
                <v-col cols="12" sm="12" lg="12" class="py-0">
                  <CompromisosXArea :datos="datos"></CompromisosXArea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
        <v-card class="my-8">
          <v-col cols="12" sm="12" lg="12" class="pt-2 pb-1">
            <h2>2. CUMPLIMIENTO POR MES/ÁREAS/ESTATUS</h2>
          </v-col>
          <v-divider></v-divider>
          <v-card color="pa-0 mt-3" elevation="0">
            <v-card-text class="py-0 px-3">
              <v-row class="pa-0 d-flex justify-end pt-4 pb-0">
                <v-col cols="12" sm="12" lg="12" class="py-0">
                  <CumplimientoXMes :datos="datos"></CumplimientoXMes>
                </v-col>
                <v-col cols="12" sm="12" lg="12" class="py-0">
                  <CumplimientoXArea :datos="datos"></CumplimientoXArea>
                </v-col>
                <v-col cols="12" sm="12" lg="12" class="py-0">
                  <CompromisosXAreaXMes :datos="datos"></CompromisosXAreaXMes>
                </v-col>
              </v-row>
              <v-row class="pa-0 d-flex justify-end py-0">
                <v-col cols="12" sm="12" lg="12" class="py-0">
                  <CompromisosXAreaXEstatus :datos="datos"></CompromisosXAreaXEstatus>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import DashboardService from '@/giplan/actas/dashboard/services/DashboardService';
import { decryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'Dashboard',

  data: () => ({
    datos: {},
    dashboardService: null,
    showLoading: false,
    page: {
      title: 'Actas'
    },
    breadcrumbs: [
      {
        text: 'Actas',
        disabled: true
      },
      {
        text: 'Dashboard',
        disabled: false
      }
    ],
    anio: new Date().getFullYear().toString(),
    listAnios: []
  }),
  components: {
    CumplimientoXMes: () => import('../components/CumplimientoXMes'),
    CumplimientoXArea: () => import('../components/CumplimientoXArea'),
    CompromisosXAreaXMes: () => import('../components/CompromisosXAreaXMes'),
    CompromisosXAreaXEstatus: () => import('../components/CompromisosXAreaXEstatus'),
    CompromisosXMes: () => import('../components/CompromisosXMes'),
    CompromisosXArea: () => import('../components/CompromisosXArea'),
    CompromisosXEstatus: () => import('../components/CompromisosXEstatus'),
    CumplimientoXCompromiso: () => import('../components/CumplimientoXCompromiso')
  },
  methods: {
    async cargarAnios() {
      this.listAnios = [];
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.dashboardService.get().execResource('cargarAnios', {
        idempresa: emp
      });
      this.listAnios = res.map((el) => {
        return { value: el.anio, text: el.anio };
      });
      this.showLoading = false;
    },
    async initialize() {
      this.datos = {};
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.dashboardService.get().execResource('dashboard', {
        idempresa: emp,
        anio: this.anio
      });
      this.datos = res;
      this.showLoading = false;
    }
  },
  async created() {
    this.dashboardService = this.$httpService(new DashboardService(), this);
    await this.cargarAnios();
    await this.initialize();
  }
};
</script>
